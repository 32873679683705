import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#cd)" }, [
      _createElementVNode("path", {
        d: "m16 17.56 6.6-6.6 1.89 1.89L16 21.33l-8.49-8.48 1.89-1.89 6.6 6.6Z",
        fill: "currentColor"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "cd" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        })
      ])
    ])
  ]))
}