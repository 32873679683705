import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#fb)" }, [
      _createElementVNode("path", {
        d: "M10 1.67a8.33 8.33 0 0 0-1.3 16.56v-5.82H6.58V10H8.7V8.16c0-2.08 1.24-3.24 3.15-3.24.9 0 1.86.17 1.86.17v2.04h-1.05c-1.04 0-1.36.65-1.36 1.3V10h2.31l-.37 2.4H11.3v5.83A8.34 8.34 0 0 0 10 1.67Z",
        fill: "currentColor"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "fb" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h20v20H0z"
        })
      ])
    ])
  ]))
}