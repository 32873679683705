import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#tw)" }, [
      _createElementVNode("path", {
        d: "M18.47 4.71c-.64.28-1.31.47-2 .55A3.5 3.5 0 0 0 18 3.33c-.68.41-1.43.7-2.21.85a3.49 3.49 0 0 0-5.94 3.18 9.9 9.9 0 0 1-7.19-3.64 3.47 3.47 0 0 0 1.08 4.65 3.47 3.47 0 0 1-1.58-.44v.05a3.49 3.49 0 0 0 2.8 3.41 3.5 3.5 0 0 1-1.57.06 3.49 3.49 0 0 0 3.25 2.43 7 7 0 0 1-5.16 1.44 9.86 9.86 0 0 0 5.34 1.57c6.41 0 9.92-5.32 9.92-9.92l-.01-.45c.68-.5 1.27-1.1 1.74-1.8Z",
        fill: "currentColor"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "tw" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h20v20H0z"
        })
      ])
    ])
  ]))
}