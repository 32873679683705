import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", {
      "clip-path": "url(#md)",
      fill: "currentColor"
    }, [
      _createElementVNode("path", { d: "M10.03 9.54c0 2.51-2.03 4.55-4.52 4.55A4.53 4.53 0 0 1 1 9.54C1 7.04 3.02 5 5.51 5c2.5 0 4.52 2.03 4.52 4.54ZM14.98 9.54c0 2.37-1.02 4.28-2.26 4.28-1.25 0-2.26-1.91-2.26-4.28 0-2.36 1.01-4.27 2.26-4.27 1.24 0 2.25 1.91 2.25 4.27M17 9.54c0 2.12-.36 3.84-.8 3.84-.43 0-.79-1.72-.79-3.84 0-2.11.36-3.83.8-3.83.43 0 .79 1.72.79 3.83Z" })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "md" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M1 5h16v9.09H1z"
        })
      ])
    ])
  ]))
}