import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24"
  }, [
    _createElementVNode("path", {
      fill: "none",
      d: "M0 0h24v24H0z"
    }),
    _createElementVNode("path", { d: "M12 4.53a6 6 0 0 1 8.48 8.46L12 21.5l-8.48-8.5A6 6 0 0 1 12 4.53z" })
  ]))
}