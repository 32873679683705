import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "12",
    height: "16",
    viewBox: "0 0 12 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M9.35497 13.7135H0.000511751C0.000240306 14.3196 0.246272 14.9009 0.684497 15.3297C1.12272 15.7585 1.71725 15.9996 2.33734 16H9.35292C9.66015 16.0001 9.96439 15.941 10.2482 15.8261C10.5321 15.7112 10.79 15.5428 11.0073 15.3304C11.2245 15.1181 11.3968 14.866 11.5144 14.5886C11.6319 14.3111 11.6924 14.0138 11.6923 13.7135V4.328C11.6924 4.317 11.6903 4.30608 11.6861 4.29589C11.6818 4.2857 11.6755 4.27644 11.6676 4.26866C11.6596 4.26088 11.6502 4.25473 11.6397 4.25058C11.6293 4.24643 11.6181 4.24437 11.6069 4.2445H9.43784C9.4268 4.24463 9.4159 4.2469 9.40577 4.25118C9.39563 4.25546 9.38647 4.26166 9.37881 4.26943C9.37115 4.2772 9.36514 4.28637 9.36113 4.29643C9.35713 4.30648 9.35521 4.31721 9.35548 4.328V13.716L9.35497 13.7135ZM9.35497 0C9.97505 0.00039771 10.5696 0.241504 11.0078 0.670291C11.446 1.09908 11.6921 1.68043 11.6918 2.2865H2.33989V11.672C2.33937 11.694 2.3302 11.7149 2.31429 11.7305C2.29838 11.746 2.27696 11.755 2.25446 11.7555H0.0854313C0.0628149 11.7554 0.0411631 11.7465 0.0251707 11.7309C0.00917821 11.7153 0.000134449 11.6941 2.24374e-07 11.672V2.2865C-0.000271221 1.68043 0.24576 1.09908 0.683985 0.670291C1.12221 0.241504 1.71674 0.00039771 2.33682 0L9.35497 0Z",
      fill: "currentColor"
    })
  ]))
}