import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#clip0_3203_62125)" }, [
      _createElementVNode("path", {
        d: "M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z",
        fill: "#F0F0F0"
      }),
      _createElementVNode("path", {
        d: "M23.2536 7.82601H10.4348V4.69556H4.17389V7.82601H0.746391C0.264047 9.12595 0 10.5321 0 11.9999C0 13.4677 0.264047 14.8738 0.746391 16.1738L12 17.2173L23.2536 16.1738C23.736 14.8738 24 13.4677 24 11.9999C24 10.5321 23.736 9.12595 23.2536 7.82601Z",
        fill: "#0052B4"
      }),
      _createElementVNode("path", {
        d: "M12.0002 23.9999C17.1598 23.9999 21.5583 20.7434 23.2538 16.1738H0.746582C2.4421 20.7434 6.84061 23.9999 12.0002 23.9999Z",
        fill: "#D80027"
      }),
      _createElementVNode("path", {
        d: "M4.17383 7.82616V8.8696C4.17383 11.2659 7.30428 12.0001 7.30428 12.0001C7.30428 12.0001 10.4347 11.2659 10.4347 8.8696V7.82616L9.39125 8.86965L7.30428 7.30444L5.21731 8.86965L4.17383 7.82616Z",
        fill: "#F0F0F0"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "clip0_3203_62125" }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          fill: "white"
        })
      ])
    ])
  ]))
}