import { default as _91_46_46_46slug_93tTVZEUyhkLMeta } from "/home/circleci/project/pages/[...slug].vue?macro=true";
import { default as _91slug_93qag9TvbfpxMeta } from "/home/circleci/project/pages/blog/news/[slug].vue?macro=true";
import { default as _91slug_9389ug6960D1Meta } from "/home/circleci/project/pages/blog/press-releases/[slug].vue?macro=true";
import { default as _91slug_93OIN99w85F8Meta } from "/home/circleci/project/pages/blog/stories/[slug].vue?macro=true";
import { default as _91slug_93H9OqNxOOaBMeta } from "/home/circleci/project/pages/blog/tech/[slug].vue?macro=true";
import { default as historic_45modern_45slavery_45statementsm2QlLiRmfqMeta } from "/home/circleci/project/pages/historic-modern-slavery-statements.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as _91slug_93rmYylLbmfnMeta } from "/home/circleci/project/pages/internal-job-referrals/[slug].vue?macro=true";
import { default as indexKk7vLDlApLMeta } from "/home/circleci/project/pages/internal-job-referrals/index.vue?macro=true";
import { default as _91slug_93bwSXm8uYlxMeta } from "/home/circleci/project/pages/jobs/[slug].vue?macro=true";
import { default as legaldOaYZipT72Meta } from "/home/circleci/project/pages/legal.vue?macro=true";
import { default as licensing_45mapG6UteZXjsDMeta } from "/home/circleci/project/pages/licensing-map.vue?macro=true";
import { default as next_45stepsjmJZEwhtGXMeta } from "/home/circleci/project/pages/next-steps.vue?macro=true";
import { default as privacy_45generalI5Dl7m2WE9Meta } from "/home/circleci/project/pages/privacy-general.vue?macro=true";
import { default as privacy_45policy_45internship_45updatemRPOt4QfZXMeta } from "/home/circleci/project/pages/privacy-policy-internship-update.vue?macro=true";
import { default as privacy_45policy_45update_45cnWjK8MLyNZoMeta } from "/home/circleci/project/pages/privacy-policy-update-cn.vue?macro=true";
import { default as privacy_45policy_45update_45ghGUZQ6Rc8KLMeta } from "/home/circleci/project/pages/privacy-policy-update-gh.vue?macro=true";
import { default as privacy_45policy_45update_45shglZjAggNL2rMeta } from "/home/circleci/project/pages/privacy-policy-update-shg.vue?macro=true";
import { default as talent_45campNUE0cnWa1qMeta } from "/home/circleci/project/pages/talent-camp.vue?macro=true";
import { default as talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta } from "/home/circleci/project/pages/talking-tom-heroes-suddenly-super.vue?macro=true";
import { default as videocreditsd1whit2YZQMeta } from "/home/circleci/project/pages/videocredits.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93tTVZEUyhkLMeta?.name ?? "slug",
    path: _91_46_46_46slug_93tTVZEUyhkLMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93tTVZEUyhkLMeta || {},
    alias: _91_46_46_46slug_93tTVZEUyhkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93tTVZEUyhkLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qag9TvbfpxMeta?.name ?? "blog-news-slug",
    path: _91slug_93qag9TvbfpxMeta?.path ?? "/blog/news/:slug()",
    meta: _91slug_93qag9TvbfpxMeta || {},
    alias: _91slug_93qag9TvbfpxMeta?.alias || [],
    redirect: _91slug_93qag9TvbfpxMeta?.redirect,
    component: () => import("/home/circleci/project/pages/blog/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9389ug6960D1Meta?.name ?? "blog-press-releases-slug",
    path: _91slug_9389ug6960D1Meta?.path ?? "/blog/press-releases/:slug()",
    meta: _91slug_9389ug6960D1Meta || {},
    alias: _91slug_9389ug6960D1Meta?.alias || [],
    redirect: _91slug_9389ug6960D1Meta?.redirect,
    component: () => import("/home/circleci/project/pages/blog/press-releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OIN99w85F8Meta?.name ?? "blog-stories-slug",
    path: _91slug_93OIN99w85F8Meta?.path ?? "/blog/stories/:slug()",
    meta: _91slug_93OIN99w85F8Meta || {},
    alias: _91slug_93OIN99w85F8Meta?.alias || [],
    redirect: _91slug_93OIN99w85F8Meta?.redirect,
    component: () => import("/home/circleci/project/pages/blog/stories/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93H9OqNxOOaBMeta?.name ?? "blog-tech-slug",
    path: _91slug_93H9OqNxOOaBMeta?.path ?? "/blog/tech/:slug()",
    meta: _91slug_93H9OqNxOOaBMeta || {},
    alias: _91slug_93H9OqNxOOaBMeta?.alias || [],
    redirect: _91slug_93H9OqNxOOaBMeta?.redirect,
    component: () => import("/home/circleci/project/pages/blog/tech/[slug].vue").then(m => m.default || m)
  },
  {
    name: historic_45modern_45slavery_45statementsm2QlLiRmfqMeta?.name ?? "historic-modern-slavery-statements",
    path: historic_45modern_45slavery_45statementsm2QlLiRmfqMeta?.path ?? "/historic-modern-slavery-statements",
    meta: historic_45modern_45slavery_45statementsm2QlLiRmfqMeta || {},
    alias: historic_45modern_45slavery_45statementsm2QlLiRmfqMeta?.alias || [],
    redirect: historic_45modern_45slavery_45statementsm2QlLiRmfqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/historic-modern-slavery-statements.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rmYylLbmfnMeta?.name ?? "internal-job-referrals-slug",
    path: _91slug_93rmYylLbmfnMeta?.path ?? "/internal-job-referrals/:slug()",
    meta: _91slug_93rmYylLbmfnMeta || {},
    alias: _91slug_93rmYylLbmfnMeta?.alias || [],
    redirect: _91slug_93rmYylLbmfnMeta?.redirect,
    component: () => import("/home/circleci/project/pages/internal-job-referrals/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKk7vLDlApLMeta?.name ?? "internal-job-referrals",
    path: indexKk7vLDlApLMeta?.path ?? "/internal-job-referrals",
    meta: indexKk7vLDlApLMeta || {},
    alias: indexKk7vLDlApLMeta?.alias || [],
    redirect: indexKk7vLDlApLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/internal-job-referrals/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bwSXm8uYlxMeta?.name ?? "jobs-slug",
    path: _91slug_93bwSXm8uYlxMeta?.path ?? "/jobs/:slug()",
    meta: _91slug_93bwSXm8uYlxMeta || {},
    alias: _91slug_93bwSXm8uYlxMeta?.alias || [],
    redirect: _91slug_93bwSXm8uYlxMeta?.redirect,
    component: () => import("/home/circleci/project/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: legaldOaYZipT72Meta?.name ?? "legal",
    path: legaldOaYZipT72Meta?.path ?? "/legal",
    meta: legaldOaYZipT72Meta || {},
    alias: legaldOaYZipT72Meta?.alias || [],
    redirect: legaldOaYZipT72Meta?.redirect,
    component: () => import("/home/circleci/project/pages/legal.vue").then(m => m.default || m)
  },
  {
    name: licensing_45mapG6UteZXjsDMeta?.name ?? "licensing-map",
    path: licensing_45mapG6UteZXjsDMeta?.path ?? "/licensing-map",
    meta: licensing_45mapG6UteZXjsDMeta || {},
    alias: licensing_45mapG6UteZXjsDMeta?.alias || [],
    redirect: licensing_45mapG6UteZXjsDMeta?.redirect,
    component: () => import("/home/circleci/project/pages/licensing-map.vue").then(m => m.default || m)
  },
  {
    name: next_45stepsjmJZEwhtGXMeta?.name ?? "next-steps",
    path: next_45stepsjmJZEwhtGXMeta?.path ?? "/next-steps",
    meta: next_45stepsjmJZEwhtGXMeta || {},
    alias: next_45stepsjmJZEwhtGXMeta?.alias || [],
    redirect: next_45stepsjmJZEwhtGXMeta?.redirect,
    component: () => import("/home/circleci/project/pages/next-steps.vue").then(m => m.default || m)
  },
  {
    name: privacy_45generalI5Dl7m2WE9Meta?.name ?? "privacy-general",
    path: privacy_45generalI5Dl7m2WE9Meta?.path ?? "/privacy-general",
    meta: privacy_45generalI5Dl7m2WE9Meta || {},
    alias: privacy_45generalI5Dl7m2WE9Meta?.alias || [],
    redirect: privacy_45generalI5Dl7m2WE9Meta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-general.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45internship_45updatemRPOt4QfZXMeta?.name ?? "privacy-policy-internship-update",
    path: privacy_45policy_45internship_45updatemRPOt4QfZXMeta?.path ?? "/privacy-policy-internship-update",
    meta: privacy_45policy_45internship_45updatemRPOt4QfZXMeta || {},
    alias: privacy_45policy_45internship_45updatemRPOt4QfZXMeta?.alias || [],
    redirect: privacy_45policy_45internship_45updatemRPOt4QfZXMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-internship-update.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45update_45cnWjK8MLyNZoMeta?.name ?? "privacy-policy-update-cn",
    path: privacy_45policy_45update_45cnWjK8MLyNZoMeta?.path ?? "/privacy-policy-update-cn",
    meta: privacy_45policy_45update_45cnWjK8MLyNZoMeta || {},
    alias: privacy_45policy_45update_45cnWjK8MLyNZoMeta?.alias || [],
    redirect: privacy_45policy_45update_45cnWjK8MLyNZoMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-update-cn.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45update_45ghGUZQ6Rc8KLMeta?.name ?? "privacy-policy-update-gh",
    path: privacy_45policy_45update_45ghGUZQ6Rc8KLMeta?.path ?? "/privacy-policy-update-gh",
    meta: privacy_45policy_45update_45ghGUZQ6Rc8KLMeta || {},
    alias: privacy_45policy_45update_45ghGUZQ6Rc8KLMeta?.alias || [],
    redirect: privacy_45policy_45update_45ghGUZQ6Rc8KLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-update-gh.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy_45update_45shglZjAggNL2rMeta?.name ?? "privacy-policy-update-shg",
    path: privacy_45policy_45update_45shglZjAggNL2rMeta?.path ?? "/privacy-policy-update-shg",
    meta: privacy_45policy_45update_45shglZjAggNL2rMeta || {},
    alias: privacy_45policy_45update_45shglZjAggNL2rMeta?.alias || [],
    redirect: privacy_45policy_45update_45shglZjAggNL2rMeta?.redirect,
    component: () => import("/home/circleci/project/pages/privacy-policy-update-shg.vue").then(m => m.default || m)
  },
  {
    name: talent_45campNUE0cnWa1qMeta?.name ?? "talent-camp",
    path: talent_45campNUE0cnWa1qMeta?.path ?? "/talent-camp",
    meta: talent_45campNUE0cnWa1qMeta || {},
    alias: talent_45campNUE0cnWa1qMeta?.alias || [],
    redirect: talent_45campNUE0cnWa1qMeta?.redirect,
    component: () => import("/home/circleci/project/pages/talent-camp.vue").then(m => m.default || m)
  },
  {
    name: talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta?.name ?? "talking-tom-heroes-suddenly-super",
    path: talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta?.path ?? "/talking-tom-heroes-suddenly-super",
    meta: talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta || {},
    alias: talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta?.alias || [],
    redirect: talking_45tom_45heroes_45suddenly_45superDpMKaV1ohiMeta?.redirect,
    component: () => import("/home/circleci/project/pages/talking-tom-heroes-suddenly-super.vue").then(m => m.default || m)
  },
  {
    name: videocreditsd1whit2YZQMeta?.name ?? "videocredits",
    path: videocreditsd1whit2YZQMeta?.path ?? "/videocredits",
    meta: videocreditsd1whit2YZQMeta || {},
    alias: videocreditsd1whit2YZQMeta?.alias || [],
    redirect: videocreditsd1whit2YZQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/videocredits.vue").then(m => m.default || m)
  }
]