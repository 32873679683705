export function useLinkType(href: string) {
  href = href.toLowerCase();

  if (href.startsWith('mailto:')) {
    return 'mail';
  }

  // Check for downloadable file extensions (accounting for trailing slash)
  if (/\.(zip|pdf|doc|docx|xls)\/?$/i.test(href)) {
    return 'download';
  }
  
  if (href.startsWith('http')) {
    return 'external';
  }

  return 'internal';
}
