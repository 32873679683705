import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#play)" }, [
      _createElementVNode("path", {
        d: "M25.83 16.55 11.7 25.98a.67.67 0 0 1-1.03-.56V6.58a.67.67 0 0 1 1.03-.56l14.13 9.43a.67.67 0 0 1 0 1.1Z",
        fill: "currentColor"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "play" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h32v32H0z"
        })
      ])
    ])
  ]))
}