import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("g", { "clip-path": "url(#china)" }, [
      _createElementVNode("path", {
        d: "M12 24a12 12 0 1 0 0-24 12 12 0 0 0 0 24Z",
        fill: "#D80027"
      }),
      _createElementVNode("path", {
        d: "m6.57 7.3 1.03 3.2h3.35l-2.7 1.96 1.03 3.2-2.71-1.98-2.72 1.97 1.04-3.19-2.71-1.97h3.35L6.57 7.3ZM14.23 18.59l-.8-.98-1.17.46.68-1.06-.79-.98 1.21.32.69-1.05.06 1.26 1.22.32-1.17.45.07 1.26ZM15.8 15.73l.38-1.2-1.03-.73 1.26-.02.37-1.2.4 1.2 1.26-.02-1 .75.4 1.19-1.03-.73-1 .76ZM17.93 8.8l-.56 1.14.9.87-1.24-.17-.55 1.12-.22-1.24-1.25-.17 1.12-.59-.22-1.24.9.87 1.12-.58ZM14.26 5.39l-.1 1.25 1.17.47-1.22.3-.09 1.26-.66-1.07-1.22.3.8-.96-.66-1.07 1.17.48.8-.96Z",
        fill: "#FFDA44"
      })
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "china" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h24v24H0z"
        })
      ])
    ])
  ]))
}