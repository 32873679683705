import { onMounted, onUnmounted, watch } from 'vue';
import { useNuxtApp } from '#imports';
import { useRoute } from 'vue-router';

export default function useTrackScrollDepth(depthsToTrack = [25, 50, 75, 100]) {
  const { $event } = useNuxtApp();
  const route = useRoute();

  let resizeObserver: ResizeObserver;
  let scrollHeight: number;
  let percentages: Array<number>;

  watch(route, () => percentages = depthsToTrack);

  function handleScrollDepth() {
    const currentPercentage = Math.round(window.scrollY / scrollHeight * 100);

    percentages.forEach(percentage => {
      if (currentPercentage >= percentage) {
        $event('Scroll Depth', { props: { [`${percentage}%`]: route.path === '/' ? 'homepage' : route.path } });
        percentages = percentages.filter(item => item !== percentage);
      }
    });
  }

  function handleResize(entries: ResizeObserverEntry[]) {
    entries.forEach(() => {
      scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    });
  }

  onMounted(() => {
    percentages = depthsToTrack;
    scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
    resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(document.documentElement);
    window.addEventListener('scroll', handleScrollDepth);
  });
  
  onUnmounted(() => {
    resizeObserver?.unobserve(document.documentElement);
    percentages = null;
    scrollHeight = null;
    resizeObserver = null;
    window.removeEventListener('scroll', handleScrollDepth);
  });
}