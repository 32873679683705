import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import lenis_client_2wiro2fsNq from "/home/circleci/project/plugins/lenis.client.ts";
import plausible_client_QgwkVYmaxk from "/home/circleci/project/plugins/plausible.client.ts";
import scroll_client_HJUo2UKx9n from "/home/circleci/project/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/home/circleci/project/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  lenis_client_2wiro2fsNq,
  plausible_client_QgwkVYmaxk,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk
]